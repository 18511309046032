import "setimmediate";
import onPageReady from "./utils/onPageReady";
import polyfill from "./polyfills/polyfill-loader";
import openExternalLinksInNewTab from "./utils/openLinksInNewTab";

const sentryDsn =
  "https://fd47a46b374042eda97e1ae8205c852b@o37692.ingest.sentry.io/6167979";

function initialise() {
  openExternalLinksInNewTab();

  if (sentryDsn) {
    import(/* webpackChunkName: "sentry" */ "./modules/sentry.js").then(
      ({ default: sentry }) => {
        sentry.init(sentryDsn);
      }
    );
  }

  /*  Tables  ------------------------ */
  const tables = document.querySelectorAll("table");
  if (tables.length > 0) {
    import(
      /* webpackChunkName: "tables" */ "./modules/responsive-tables.js"
    ).then(({ default: tablesInit }) => {
      tablesInit([...tables]);
    });
  }

  /*  Collapsibles  ------------------------ */
  const collapsibleEls = document.querySelectorAll(
    "[data-collapse-control], .collapse-control"
  );
  if (collapsibleEls.length > 0) {
    import(
      /* webpackChunkName: "collapsible" */ "./modules/collapsible.js"
    ).then(({ default: collapsibleInit }) => {
      collapsibleInit([...collapsibleEls]);
    });
  }

  /*  Parallax  ------------- */
  const parallaxEls = document.querySelectorAll(".js-parallax");
  if (parallaxEls.length > 0) {
    import(/* webpackChunkName: "parallax" */ "./modules/parallax.js").then(
      ({ default: parallaxInit }) => {
        parallaxInit([...parallaxEls]);
      }
    );
  }

  const textAreas = document.querySelectorAll("textarea");
  if (textAreas.length > 0) {
    import("./modules/textarea-autosize.js").then(({ default: init }) => {
      init([...textAreas]);
    });
  }

  /*  Education portal Register  ------------------------ */
  const registerMountNode = document.getElementById("register-mount-node");
  if (registerMountNode) {
    import(/* webpackChunkName: "register" */ "./modules/register.js").then(
      ({ default: init }) => {
        init(registerMountNode);
      }
    );
  }

  /*  Action plan App  ------------------------ */
  const actionPlanMountNode = document.getElementById("action-plan-mount-node");
  if (actionPlanMountNode) {
    import(
      /* webpackChunkName: "action-plan" */ "./modules/action-plan.js"
    ).then(({ default: init }) => {
      init(actionPlanMountNode);
    });
  }


  const subscriptionForm = document.getElementById("subscription-form");
  if (subscriptionForm) {
    import("./modules/subscription-cta.js").then(
      ({ default: subscriptionInit }) => {
        subscriptionInit(subscriptionForm);
      }
    );
  }

  const modals = document.querySelectorAll(".modal");
  if (modals.length > 0) {
    import("./modules/modals.js").then(({ default: init }) => {
      init();
    });
  }

  const videoModals = document.querySelectorAll("[data-video-modal]");
  if (videoModals.length > 0) {
    import("./modules/video-modal.js").then(({ default: init }) =>
      init(videoModals)
    );
  }

  const bannerScrollButtons = document.querySelectorAll(
    ".banner-scroll-button"
  );
  if (bannerScrollButtons.length > 0) {
    import("./modules/banner-scroll-button.js").then(({ default: init }) =>
      init(bannerScrollButtons)
    );
  }

  /*  Simple pagination  ------------- */
  const listingElem = document.querySelectorAll("[data-listing]");
  if (listingElem.length > 0) {
    import(
      /* webpackChunkName: "simple-pagination" */ "./modules/pagination.js"
    ).then(({ default: paginationInit }) => {
      paginationInit(listingElem[0]);
    });
  }

  const shareIcons = document.querySelectorAll("[data-share-url]");
  if (shareIcons.length > 0) {
    import(
      /* webpackChunkName: "share-icons" */ "./modules/share.widget.js"
    ).then(({ default: sharingInit }) => {
      sharingInit([...shareIcons]);
    });
  }

  /* Campaign header */
  const campaignNav = document.querySelector(".campaign-header");
  if (campaignNav) {
    import(
      /* webpackChunkName: "campaign-nav" */ "./modules/mobile-menu-toggle.js"
    ).then(({ default: campaignNavInit }) => {
      campaignNavInit(campaignNav);
    });
  }

  /* Desktop Menu -- Platform header */
  const desktopNav = document.querySelector(
    ".desktop-header-nav-links.platform-header"
  );
  if (desktopNav) {
    import(
      /* webpackChunkName: "desktop-nav" */ "./modules/desktop-nav.js"
    ).then(({ default: desktopNavInit }) => {
      desktopNavInit(desktopNav);
    });
  }

  /*  Mobile Menu  -- Platform header ------------------------- */
  const mobileNavEl = document.querySelector(".mobile-nav");
  if (mobileNavEl) {
    import(/* webpackChunkName: "mobile-nav" */ "./modules/mobile-nav.js").then(
      ({ default: MobileNav }) => {
        new MobileNav(mobileNavEl);
      }
    );
  }

  /*  Counter for action plan stats block ------------------------- */
  const statsBlock = document.querySelectorAll(".stats-block");
  if (statsBlock.length > 0) {
    import(/* webpackChunkName: "counter-up" */ "./modules/counter-up.js").then(
      ({ default: init }) => init(statsBlock)
    );
  }
}

polyfill().then(() => onPageReady(initialise));
