export default function openLinksInNewTab() {
    setTimeout(() => {
        const links = [...document.querySelectorAll('a')];

        if (links.length > 0) {
            links.forEach(it => {
                if (it.host !== window.location.host) {
                    it.setAttribute('target', '_blank');
                    it.setAttribute('rel', 'noopener noreferrer');
                }
            });
        }
    });
}
